<template>
	<v-row align="center" justify="center">
		<v-col cols="12" lg="8" xl="6">
			<v-card class="mx-auto">
				<v-card-title class="title font-weight-regular justify-space-between">
					<span>{{ currentTitle }}</span>
					<v-avatar
						v-if="step !== 3"
						color="primary lighten-2"
						class="subheading white--text"
						size="24"
						v-text="stepDisplay"
					></v-avatar>
				</v-card-title>

				<v-form :disabled="disabled || loading" ref="form" lazy-validation v-model="valid" @submit.prevent>
					<v-window :value="step" touchless>
						<v-window-item :value="1">
							<v-card-text>
								<EmailVerification tabindex="1" :disabled="loading || step != 1" :email.sync="email"/>
							</v-card-text>
						</v-window-item>

						<v-window-item :value="2">
							<v-card-text>
								<PasswordConfirmation v-if="step === 2" :password.sync="password" />
							</v-card-text>
						</v-window-item>

						<v-window-item :value="3">
							<v-card-text>
								<v-progress-linear
									v-if="step === 3"
									indeterminate
									color="primary"
									:size="100"
									:width="7"
								/>
							</v-card-text>
						</v-window-item>

						<v-window-item :value="4">
							<div class="pa-4 text-center">
								<v-img
									class="mb-4"
									contain
									height="128"
									:src="require('../../assets/logo.svg')"
								></v-img>
								<h3 class="title font-weight-light mb-2">
									Finished!
								</h3>
								<span class="caption grey--text">Thanks for registering!</span>
							</div>
						</v-window-item>
					</v-window>

					<v-divider v-if="step !== 3"></v-divider>

					<v-card-actions v-if="step !== 3">
						<v-spacer></v-spacer>
						<v-btn v-if="step < 3"
							:disabled="disabled || loading || !valid"
							:loading="loading"
							type="submit"
							color="primary"
							depressed
							@click="handleNextClicked"
						> Next </v-btn>

						<v-btn v-else
							:disabled="disabled || loading || !valid"
							:loading="loading"
							color="primary"
							type="submit"
							depressed
							@click="$emit('complete', credentials)"
						> Complete </v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import { reactive, computed, toRefs, onBeforeUnmount } from "@vue/composition-api";
import { signInWithEmailLink, updatePassword, onAuthStateChanged } from "firebase/auth";
import { auth, registerUser } from "@/services/firebase";
import loadingProxy from "@/lib/loading-proxy";
import EmailVerification from "./EmailVerification";
import PasswordConfirmation from "./PasswordConfirmation";

const mapFirebaseError = (err) => {
	const code = err.code
	let message = "Failed to validate registration, please contact the administrator.";
	if(code) {
		switch(code) {
			case "auth/user-not-found": message = "The email you provided is not associated with an active account."; break;
			case "auth/invalid-email": message = "The email you provided does not match."; break;
			case "auth/invalid-action-code": message = "Your invitation link is invalid or has already been used."; break;
			case "auth/expired-action-code": message = "Your invitation link has expired."; break;
		}
	}
	if(process.env.NODE_ENV !== "test") console.log(err)
	return { code, message };
};

export default {
	name: "UserRegistrationForm",
	components: { EmailVerification, PasswordConfirmation },
	props: {
		invitation:{ type:String, required:true },
		disabled:{ type:Boolean, default:() => false },
		signinLink:{ type:String, required:true },
	},
	emits: ["error", "complete"],

	setup(props, {emit}) {
		const state = reactive({
			form: null,

			step: 1,
			stepDisplay: computed(() => state.step === 4 ? 3 : state.step),
			currentTitle: computed(() => {
				switch(state.step) {
					case 1: return "Verify Email";
					case 2: return "Enter Password";
					case 3: return "Registering";
					default: return "Account created";
				}
			}),

			email:"",
			password:"",
			loading: false,
			valid: true,
		});

		const unsubscribe = onAuthStateChanged(auth, user => {
			if(!user) return;
			if(!state.email && user.email && user.emailVerified) {
				state.email = user.email;
				state.step = 2;
			}
		});
		onBeforeUnmount(unsubscribe);

		const emailValidationStep = loadingProxy(() => {
			return signInWithEmailLink(auth, state.email, props.signinLink)
				.then(() => state.step++)
				.catch(err => emit("error", mapFirebaseError(err)))
		}, state, "loading");

		const updatePasswordStep = loadingProxy(() => {
			return updatePassword(auth.currentUser, state.password)
				.then(() => state.step++)
				.catch(err => emit("error", err.message));
		}, state, "loading");

		const registerStep = loadingProxy(() => {
			const { email } = state;
			const { invitation } = props;
			return registerUser({email, invitation})
				.then(({data}) => {
					if(!data.ok) return emit("error", data.message);
					state.step++;
				})
				.catch(err => emit("error", mapFirebaseError(err)))
		}, state, "loading");

		const handleNextClicked = () => {
			if(!state.form.validate()) return;
			if(state.step === 1) return emailValidationStep();
			if(state.step === 2) return updatePasswordStep().then(registerStep);
		};

		const credentials = computed(() => {
			const {	email } = state;
			return { email };
		});

		return { ...toRefs(state), handleNextClicked, credentials };
	}
}
</script>
