<template>
	<v-row align="center" justify="center">
		<v-col cols="12" sm="4">

			<v-card tile
				class="pa-4"
				:class="{'shake' : errorOccurred}"
			>
				<v-form
					v-if="validCode && !verifyingCode"
					v-model="validForm"
					@submit.prevent="onSubmit"
				>
					<v-card-title>Reset your password</v-card-title>
					<v-card-text>
						<PasswordConfirmation :password.sync="password"/>
					</v-card-text>

					<v-card-actions>
						<v-spacer/>
						<v-btn
							:disabled="busy || !validForm || requiresChange"
							:loading="busy"
							color="primary"
							type="submit"
						> Reset Password </v-btn>
					</v-card-actions>
				</v-form>

				<v-card-text v-else>
					<router-link replace to="/">
						<v-img contain
							:src="require('../assets/logo.svg')"
							class="my-3"
							height="200"
						/>
					</router-link>

					<p class="text-h5">Try resetting your password again.</p>
					<p class="text-subtitle-1">Your request to reset your password has expired or the link has already been used.</p>
				</v-card-text>
			</v-card>

		</v-col>
	</v-row>
</template>

<script>
import { reactive, toRefs, onMounted, watch } from "@vue/composition-api";
import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import { auth } from "@/services/firebase";
import loadingProxy from "@/lib/loading-proxy";
import useSnackbar, { CLOSE_ACTION } from "@/composables/useSnackbar";
import PasswordConfirmation from "@/components/registration/PasswordConfirmation";
import router from "@/router";

const mapLoginError = (err) => {
	const code = err.code
	if(code) {
		switch(code) {
			case "auth/user-not-found": return "Account not found. Your account may have recently been deleted";
			case "auth/user-disabled": return "Account disabled. Contact administration for assistance.";
			case "auth/weak-password": return "New password is not strong enough.";
		}
	}
	return "Failed to reset password, please try again later.";
};

export default {
	name: "ResetPassword",
	components: { PasswordConfirmation },

	setup(_props, {root}) {
		const code = root.$route.query.oobCode || "";

		onMounted(() => {
			state.verifyingCode = true;
			verifyPasswordResetCode(auth, code).then(email => {
				state.validCode = true;
				state.email = email;
			}).catch(() => {
				state.validCode = false;
				state.errorOccurred = true;
			}).finally(() => {
				state.verifyingCode = false;
			});
		});

		const snackbar = useSnackbar();
		const state = reactive({
			email:"",
			password:"",

			validForm: false,
			busy: false,
			requiresChange: false,
			errorOccurred: false,
			verifyingCode: true,
			validCode: false,
		});

		const resetError = () => state.errorOccurred = false;

		const handleError = (err) => {
			state.requiresChange = true;
			state.errorOccurred = true;
			showErrorMessage(mapLoginError(err), 0);
			setTimeout(resetError, 1000);
		};

		const showErrorMessage = (msg, timeout) => {
			if(snackbar.state.show) {
				snackbar.hide();
				setTimeout(() => snackbar.show(msg, {action:CLOSE_ACTION, timeout}), 250);
			}else{
				snackbar.show(msg, {action:CLOSE_ACTION, timeout});
			}
		};

		const changeRouteAfterDelay = (location, delay) => new Promise((resolve, reject) => {
			setTimeout(() => {
				router.replace(location)
					.then(resolve)
					.catch(reject);
			}, delay);
		});

		const onSubmit = loadingProxy(() => {
			resetError();
			snackbar.hide();
			return confirmPasswordReset(auth, code, state.password)
				.then(() => {
					snackbar.show("Password reset!", {action:CLOSE_ACTION});
					const location = state.email ? `/login?email=${state.email}` : "/login";
					return changeRouteAfterDelay(location, 1500);
				})
				.catch(handleError)
		}, state, "busy");

		watch(() => state.password, () => {
			state.requiresChange = false;
		});

		return { ...toRefs(state), onSubmit };
	}
}
</script>
