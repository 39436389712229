<template>
	<v-icon tabindex="-1" v-if="isValidInput" :disabled="disabled" @click="$emit('input', defaultValue)">mdi-close</v-icon>
</template>

<script>
import { computed } from "@vue/composition-api";

export default {
	name: "InputClearableBtn",
	components: { },
	props: {
		value: { required:true },
		disabled: { type:Boolean, default:false },
		validator: { type:Function },
		defaultValue: { default: "" },
	},

	setup(props) {
		const isValidInput = computed(() => {
			if(!props.validator) return props.value && props.value.length;
			return props.validator(props.value);
		});

		return { isValidInput };
	}
}
</script>
