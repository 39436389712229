export default function buildProxy(fn, target, property) {
	const setLoading = (loading) => target[property] = loading;
	const notLoading = setLoading.bind(null, false);

	const handler = {
		apply: function(target, thisArg, argumentList) {
			setLoading(true);
			const result = target.apply(thisArg, argumentList);
			return Promise.resolve(result).finally(notLoading);
		}
	};

	return new Proxy(fn, handler);
}
