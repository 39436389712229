<template>
	<div>
		<v-text-field
			v-bind="$attrs"
			v-model="model"
			name="email"
			label="Email address"
			type="email"
			:rules="rules"
		>
			<template v-slot:append>
				<InputClearableBtn v-model="model" @click="$emit('input', '')"/>
			</template>
		</v-text-field>
		<span class="caption grey--text text--darken-1">
			This is the email address you will use to login to your account
		</span>
	</div>
</template>

<script>
import { reactive, toRefs } from "@vue/composition-api";
import { useModelWrapper } from "@/logic/model-wrapper";
import InputClearableBtn from "@/components/InputClearableBtn";

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export default {
	name: "EmailVerification",
	components: { InputClearableBtn },
	props: { email:String },

	setup(props, {emit}) {
		const state = reactive({
			model: useModelWrapper(props, emit, "email"),

			rules: [
				v => !!v || "Required",
				v => EMAIL_REGEX.test(v) || "Must be a valid email address",
			],
			busy: false,
		});

		return { ...toRefs(state) };
	}
}
</script>
