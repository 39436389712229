<template>
	<v-row align="center" justify="center">
		<v-col cols="12" sm="8" md="6" class="text-center">
			<UserRegistrationForm
				:invitation="invitation"
				:disabled="invalidSignInLink || loading"
				:signin-link="signinLink"
				@complete="onRegistrationComplete"
				@error="onRegistrationError"
			/>

		</v-col>
	</v-row>
</template>

<script>
import { reactive, toRefs } from "@vue/composition-api";
import { isSignInWithEmailLink } from "firebase/auth";
import loadingProxy from "@/lib/loading-proxy";
import useSnackbar from "@/composables/useSnackbar";
import { auth } from "@/services/firebase";
import useSession from "@/composables/useSession";
import useUser from "@/composables/useUser";
import router from "@/router";

import UserRegistrationForm from "@/components/registration/UserRegistrationForm";

export default {
	name: "Registration",
	components: { UserRegistrationForm },

	beforeRouteEnter (to, from, next) {
		const { loggedIn } = useUser();
		if(!loggedIn.value) return next();
		next("/dashboard");
	},

	setup() {
		const session = useSession();
		const snackbar = useSnackbar();

		const state = reactive({
			email:"",
			rules: {
				required: value => !!value || "Required",
			},
			loading: false,
			invitation: "",
			signinLink: "",
		});

		const registration = {
			incomplete: false,
			link: window.location.href,
			query: router.currentRoute.query,
		};
		const { apiKey, oobCode, mode, lang, invitation } = router.currentRoute.query;

		if(apiKey && oobCode  && mode && lang) {
			registration.link = window.location.href;
			registration.query = router.currentRoute.query;
		} else {
			let savedState = window.localStorage.getItem("registration");
			if(savedState) {
				savedState = JSON.parse(savedState);
				registration.incomplete = savedState.incomplete;
				registration.link = savedState.link;
				registration.query = savedState.query;
			}
		}
		state.signinLink = registration.link;

		const invalidSignInLink = !isSignInWithEmailLink(auth, registration.link);
		if(invalidSignInLink || !invitation) {
			window.localStorage.removeItem("registration");
			snackbar.showWithoutAction("Invalid registration link! Redirecting to login...");
			setTimeout(() => router.replace("/login"), 7500);
		} else {
			registration.incomplete = true;
			window.localStorage.setItem("registration", JSON.stringify(registration));
			state.invitation = invitation;
		}

		const onRegistrationComplete = loadingProxy(() => {
			window.localStorage.removeItem("registration");
			router.replace("/",
				() => session.create(auth.currentUser.uid),
			);
		}, state, "loading");

		const onRegistrationError = function({code, message}) {
			snackbar.showWithoutAction(message);
			const invalidOrExpiredCode = code === "auth/invalid-action-code" || code === "auth/expired-action-code";
			if(invalidOrExpiredCode) window.localStorage.removeItem("registration");
		}
		return { ...toRefs(state), onRegistrationComplete, onRegistrationError, invalidSignInLink };
	}
}
</script>
