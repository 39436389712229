<template>
	<div>
		<PasswordTextField
			label="Password"
			type="password"
			v-model="_password"
			:rules="passwordRules"
			@input="delayedValidate(50);"
		></PasswordTextField>
		<PasswordTextField
			ref="confirmationField"
			label="Confirm Password"
			type="password"
			v-model="passwordConfirmation"
			:rules="passwordConfirmationRules"
		></PasswordTextField>
		<span class="caption grey--text text--darken-1">
			Please enter a password for your account
		</span>
	</div>
</template>

<script>
import { reactive, toRefs } from "@vue/composition-api";
import { useModelWrapper } from "@/logic/model-wrapper";
import PasswordTextField from "@/components/inputs/PasswordTextField";

export default {
	name: "PasswordConfirmation",
	components: { PasswordTextField },
	props: {
		password:String,
	},

	setup(props, {emit}) {
		const state = reactive({
			_password: useModelWrapper(props, emit, "password"),
			passwordConfirmation: "",
			busy: false,
			confirmationField: null,
		});

		const rules = {
			required: v => !!v || "Required",
			minLength: (min) => v => (v && min <= v.length) || `Must be at ${min} characters long`,
		};

		const passwordRules = [
			rules.required,
			rules.minLength(8),
		];
		const passwordConfirmationRules = [
			rules.required,
			v => !!v && v===props.password || "Passwords must match",
		];

		let handle;
		const delayedValidate = (delay) => {
			if(handle) clearTimeout(handle);
			handle = setTimeout(() => state.confirmationField.validate(true), delay);
		};

		return { ...toRefs(state), passwordRules, passwordConfirmationRules, delayedValidate };
	}
}
</script>
