<template>
	<v-row align="center" justify="center">
		<v-col cols="12" sm="8" md="6" lg="4" xl="3">

			<v-card tile
				class="pa-4"
				:class="{'shake' : loginError}"
			>
				<v-img contain
					:src="require('../assets/logo.svg')"
					class="my-3"
					height="200"
				/>
				<v-slide-y-transition hide-on-leave>
					<v-form v-model="validForm" :disabled="busy" @submit.prevent="onSubmit();">
						<v-card-title>Login to Loftly</v-card-title>
						<v-card-text>
							<v-text-field outlined
								v-model="email"
								name="email"
								label="Email address"
								type="email"
								:rules="[rules.required, rules.email]"
								@input="requiresChange = false"
							>
								<template v-slot:append>
									<InputClearableBtn v-model="email" :disabled="busy"/>
								</template>
							</v-text-field>

							<PasswordTextField outlined
								ref="passwordInput"
								v-model="password"
								name="password"
								label="Password"
								:disabled="busy"
								:rules="[rules.required]"
								@input="requiresChange = false"
							>
							</PasswordTextField>
						</v-card-text>

						<v-card-actions>
							<router-link :to="forgotPasswordRoute">Forgot Password?</router-link>
							<v-spacer/>
							<v-btn
								:disabled="signedIn || busy || !validForm || requiresChange"
								:loading="busy"
								color="primary"
								type="submit"
							> Login </v-btn>
						</v-card-actions>
					</v-form>
				</v-slide-y-transition>
			</v-card>

		</v-col>
	</v-row>
</template>

<script>
import { reactive, computed, toRefs } from "@vue/composition-api";
import useUser from "@/composables/useUser";
import useSnackbar, { CLOSE_ACTION } from "@/composables/useSnackbar";
import InputClearableBtn from "@/components/InputClearableBtn";
import PasswordTextField from "@/components/inputs/PasswordTextField";

const mapLoginError = ({code}) => {
	if(code) {
		switch(code) {
			case "auth/wrong-password":
			case "auth/user-not-found": return "Invalid username/password!";
			case "auth/user-disabled": return "Account disabled. Please contact your administrator.";
		}
	}
	return "Login failure. Please try again later.";
};

export default {
	name: "Login",
	components: { InputClearableBtn, PasswordTextField },

	setup(props, {root}) {
		window.localStorage.setItem("registering", false);

		const query = root.$route.query;

		const snackbar = useSnackbar();
		const { login } = useUser();
		const state = reactive({
			validForm: false,
			email: query.email || "",
			password:"",
			showPass: false,
			rules: {
				required: value => !!value || "Required",
				email: v => /.+@.+\..+/.test(v) || "E-mail must be valid",
			},
			busy: false,
			signedIn: false,
			requiresChange: false,
			loginError: null,
			passwordInput: null,
			forgotPasswordRoute: computed(
				() => state.email
					? `/forgot_password?email=${state.email}`
					: "/forgot_password"
			),
		});

		const resetLoginError = () => state.loginError = null;

		const onLoginError = (err) => {
			state.requiresChange = true;
			state.loginError = err = mapLoginError(err);
			showErrorMessage(err, 0);
			setTimeout(() => state.passwordInput.focus(), 250);
			setTimeout(resetLoginError, 1000);
		};

		const showErrorMessage = (msg, timeout) => {
			if(snackbar.state.show) {
				snackbar.hide();
				setTimeout(() => snackbar.show(msg, {action:CLOSE_ACTION, timeout}), 250);
			}else{
				snackbar.show(msg, {action:CLOSE_ACTION, timeout});
			}
		};

		const onSubmit = () => {
			state.busy = true;
			resetLoginError();
			snackbar.hide();

			const {email, password} = state;
			return login({email, password})
				.then(() => state.signedIn = true) // intentionally leave busy = true
				.catch(err => {
					onLoginError(err);
					state.busy = false;
				});
		};

		return { ...toRefs(state), onSubmit, snackbar };
	}
}
</script>
