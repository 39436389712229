<template>
	<v-row align="center" justify="center">
		<v-col cols="12" sm="4">

			<v-card tile
				class="pa-4"
				:class="{'shake' : errorOccurred}"
			>
				<router-link replace to="/">
					<v-img contain
						:src="require('../assets/logo.svg')"
						class="my-3"
						height="200"
					/>
				</router-link>

				<v-form v-model="validForm" @submit.prevent="onSubmit" v-if="!complete">
					<v-card-title>Reset your password</v-card-title>
					<v-card-text>
						<p class="text-subtitle-1 font-weight-bold">
							Enter your user account and we will send you a password reset link.
						</p>
						<v-text-field outlined
							ref="emailInput"
							v-model="email"
							name="email"
							label="Email address"
							type="email"
							:disabled="busy"
							:rules="[rules.required, rules.email]"
						>
							<template v-slot:append>
								<InputClearableBtn :disabled="busy" v-model="email"/>
							</template>
						</v-text-field>
					</v-card-text>

					<v-card-actions>
						<v-spacer/>
						<v-btn
							:disabled="submitDisabled"
							:loading="busy"
							color="primary"
							type="submit"
						> Send password reset email </v-btn>
					</v-card-actions>
				</v-form>

				<v-card-text v-else class="text-h6 text--secondary">
					Reset password instructions sent to your email: <span class="text--primary">{{email}}</span>
				</v-card-text>
			</v-card>

		</v-col>
	</v-row>
</template>

<script>
import { reactive, computed, toRefs } from "@vue/composition-api";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "@/services/firebase";
import loadingProxy from "@/lib/loading-proxy";
import useSnackbar, { CLOSE_ACTION } from "@/composables/useSnackbar";
import InputClearableBtn from "@/components/InputClearableBtn";

const mapLoginError = (err) => {
	const code = err.code
	if(code) {
		switch(code) {
			case "auth/user-not-found": return "The email you provided is not associated with an active account.";
		}
	}
	return "Failed to reset password, please try again later.";
};

export default {
	name: "ForgotPassword",
	components: { InputClearableBtn },

	setup(props, {root}) {
		const query = root.$route.query;
		const snackbar = useSnackbar();
		const state = reactive({
			email: query.email || "",
			validForm: false,
			rules: {
				required: value => !!value || "Required",
				email: v => /.+@.+\..+/.test(v) || "E-mail must be valid",
			},
			busy: false,
			errorOccurred: null,
			emailInput: null,

			forgotPassword: false,
			complete: false,
			submitDisabled: computed(() => state.busy || !state.validForm),
		});

		const resetLoginError = () => state.errorOccurred = null;

		const onLoginError = (err) => {
			state.errorOccurred = err = mapLoginError(err);
			showErrorMessage(err, 0);
			setTimeout(() => state.emailInput.focus(), 250);
			setTimeout(resetLoginError, 1000);
		};

		const showErrorMessage = (msg, timeout) => {
			if(snackbar.state.show) {
				snackbar.hide();
				setTimeout(() => snackbar.show(msg, {action:CLOSE_ACTION, timeout}), 250);
			}else{
				snackbar.show(msg, {action:CLOSE_ACTION, timeout});
			}
		};

		const onSubmit = loadingProxy(() => {
			resetLoginError();
			snackbar.hide();

			const email = state.email;
			const returnUrl  = `${location.origin}`;
			return sendPasswordResetEmail(auth, email, { url:returnUrl  })
				.then(() => state.complete = true)
				.catch(onLoginError);
		}, state, "busy");

		return { ...toRefs(state), onSubmit, snackbar };
	}
}
</script>
