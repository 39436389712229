<template>
	<v-text-field
		ref="input"
		v-bind="$attrs"
		v-model="internalValue"
		:name="$attrs.name || 'password'"
		:label="$attrs.label || 'Password'"
		:type="showPass ? 'text' : 'password'"
		@input="$emit('input', $event)"
	>
		<template v-slot:append>
			<v-icon @click="showPass = !showPass" tabindex="-1" :disabled="$attrs.disabled || false">
				{{ showPass ? "mdi-eye-off" : "mdi-eye" }}
			</v-icon>
			<InputClearableBtn v-model="internalValue" :disabled="$attrs.disabled || false" @click="$emit('input', '')"/>
		</template>
	</v-text-field>
</template>

<script>
import { reactive, toRefs } from "@vue/composition-api";
import InputClearableBtn from "@/components/InputClearableBtn";

export default {
	name: "PasswordTextField",
	components: { InputClearableBtn },
	props: {
		value: { required: true, default:"" },
	},

	setup(props) {
		const state = reactive({
			internalValue: props.value,
			showPass: false,
			input: null,
		});
		const focus = () => state.input.focus();
		const validate = (val) => state.input.validate(val);

		return { ...toRefs(state), focus, validate };
	}
}
</script>
